const $ = window.$;
const _ = window._;
const sVue = window.sVue;
import Cookies from "js-cookie";
import Log from "../../../common/modules/log";
import Modal from "../../../common/modules/modal";
import PlusOne from "../../../common/modules/plusone";
import List from "../../../common/modules/ownlist";
const localStorage = require("../../../common/modules/localStorage");

$(document).ready(function () {
  // デフォルト非表示
  $(".related-entries .hide-entry").hide();

  $(".js-toggle-related-entries").on("click", function () {
    if ($(this).html().indexOf("More") > -1) {
      $(this).html("<i class=\"fa-solid fa-chevron-up mr4\"></i>Less related entries");
      $(".related-entries .hide-entry").show();
    } else {
      $(this).html("<i class=\"fa-solid fa-chevron-down mr4\"></i>More related entries");
      $(".related-entries .hide-entry").hide();
    }
  });

  const hasTouchEvent = ("ontouchstart" in window);

  // 左カラムのAdd成功表示
  const renderSuucessLeftColumn = function (animeId) {
    $("#myinfoDisplay").html("");
    $("#addtolist").html(`Successfully added. <a href="/ownlist/anime/${Number(animeId)}/edit">Edit Details</a>`);
    $("#addtolist").slideDown();
    $("#showAddtolistAnime").addClass("on");
    $("mal-tooltip").remove();
  }

  // 漫画: 左カラムのAdd成功表示
  // TODO: マンガ関連の処理はmanga.jsに移動したい
  const renderSuucessMangaLeftColumn = function () {
    $("#myinfoDisplay").html("");
    $("#addtolist").html("Successfully added.");
    $("#addtolist").slideDown();
    $("#showAddtolistManga").addClass("on");
    $("mal-tooltip").remove();
  }

  // 右カラムと同期
  const syncRightColumn = function (score, status, episodes) {
    $(".js-user-status-block .js-form-user-status-btn").remove();
    $(".js-user-status-block .js-form-user-status-select").prop("disabled", false).show();

    $(".js-user-status-block #myinfo_score").val(score);
    $(".js-user-status-block #myinfo_status").val(status);
    $(".js-user-status-block #myinfo_watchedeps").val(episodes);
    $(".js-user-status-block").removeClass("on");
  }

  // 右カラムのステータスとのみ同期
  const syncRightColumnStatus = function (status) {
    $(".js-user-status-block .js-form-user-status-btn").remove();
    $(".js-user-status-block .js-form-user-status-select").prop("disabled", false).show();

    $(".js-user-status-block #myinfo_status").val(status);
    $(".js-user-status-block").removeClass("on");
  }

  // アニメ個別ページであるか判定（見る要素が適切で無いので本来はbodyタグで識別したい）
  if ($(".js-anime-addtolist-block").exists()) {
    window.MAL.SNSFunc.initializeFacebook();

    // 左カラムのAdd to My Listボタンの処理
    $("#showAddtolistAnime").on("click", function () {
      $("#addtolist").slideToggle();
      $(this).toggleClass("on");
    });

    // 左カラムのWatch Episodeボタンの処理
    $(".js-broadcast-button").on("click", function() {
      if ($(this).data('raw') === undefined ||
        $(this).data('raw') == '') {
        return;
      }
      Modal.generate();
      Modal.buildBroadcastDialog(
        $(this).data('title'),
        $(this).data('subtitle'),
        $(this).data('raw')
      );
      Modal.show();
    });

    // 左カラムのNotify on Air登録ボタンの処理
    $("#js-notify-button").on("click", function() {

      const animeId  = $("#myinfo_anime_id").val();
      const status   = 6; // plan_to_watch
      const score    = $("#myinfo_score").val();
      const episodes = $("#myinfo_watchedeps").val();

      $("#myinfoDisplay").html("<i class='fa-solid fa-spinner fa-spin'></i>");

      List.addAnimeEntry(animeId, {
        status  : status,
        score   : score,
        episodes: episodes,
      }, {
        onSuccess() {
          $("#notify-msg-frame").show();
          renderSuucessLeftColumn(animeId);

          // 右カラムと同期
          syncRightColumnStatus(status);
        },
        onFail(error) {
          $("#myinfoDisplay").html("");
          $("#addtolist").text(error);
        },
      });
    })

    // 左カラムのAddボタン
    $(".js-anime-addtolist-block .js-anime-add-button").on("click", function () {
      // anime add
      const animeId  = $(".js-anime-addtolist-block #myinfo_anime_id").val();
      const score    = $(".js-anime-addtolist-block #myinfo_score").val();
      const status   = $(".js-anime-addtolist-block #myinfo_status").val();
      const episodes = $(".js-anime-addtolist-block #myinfo_watchedeps").val();

      $("#myinfoDisplay").html("<i class='fa-solid fa-spinner fa-spin'></i>");

      List.addAnimeEntry(animeId, {
        status  : status,
        score   : score,
        episodes: episodes,
      }, {
        onSuccess() {
          renderSuucessLeftColumn(animeId);

          // 右カラムと同期
          syncRightColumn(score, status, episodes);
        },
        onFail(error) {
          $("#myinfoDisplay").html("");
          $("#addtolist").text(error);
        },
      });
    });

    // 左カラムのUpdateボタン
    const updateOnLeftColumn = (reason) => {
      const animeId  = $(".js-anime-addtolist-block #myinfo_anime_id").val();
      const score    = $(".js-anime-addtolist-block #myinfo_score").val();
      const status   = $(".js-anime-addtolist-block #myinfo_status").val();
      const episodes = $(".js-anime-addtolist-block #myinfo_watchedeps").val();

      $("#myinfoDisplay").html("<i class='fa-solid fa-spinner fa-spin'></i>");

      // 右カラム更新不可
      $(".js-user-status-block").addClass("on");
      // Clear preparing plus one dialog
      PlusOne.clearVoteDialog();

      List.updateAnimeEntry(animeId, {
        status  : status,
        score   : score,
        episodes: episodes,
      }, {
        onSuccess() {
          $("#myinfoDisplay").html("Successfully Updated");

          // 右カラムと同期
          syncRightColumn(score, status, episodes);
          // Show plus one dialog
          if (reason == 'episode') {
            PlusOne.showAnimeVoteDialog(animeId, episodes);
          }
        },
        onFail() {
          $("#myinfoDisplay").html("Failed to Update");
        },
      });
    }

    $(".js-anime-addtolist-block .js-anime-update-button").on("click", updateOnLeftColumn);
    $(".js-anime-addtolist-block .js-anime-increment-episode-button").on("click", function () {
      const $episodes  = $(".js-anime-addtolist-block #myinfo_watchedeps");
      const currentEps = parseInt($episodes.val()) || 0;
      const totalEps   = parseInt($("#curEps").html()) || 0;
      if (totalEps === 0 || currentEps < totalEps) {
        $episodes.val(currentEps + 1);
        updateOnLeftColumn('episode');
      }
    });

    // 左カラムのステータスドロップダウン
    $(".js-anime-addtolist-block .js-anime-status-dropdown").on("change", function () {
      if ($(".js-anime-addtolist-block #myinfo_status").val() === "2") {
        $(".js-anime-addtolist-block #myinfo_watchedeps").val($("#curEps").text());
      }
    });

    // Similar Recommendationのトグルボタン
    $(".js-similar-recommendations-button").on("click", function () {
      $(`#simaid${$(this).data("id")}`).toggle();
    });

    // 右上のEdit Anime Informationボタン
    $(".js-anime-edit-info-button").on("click", function () {
      $("#editdiv").toggleClass("on");
      $("#editdiv").on("click", function (e) {
        e.stopPropagation();
      });
      setTimeout(function () {
        $(document).on("click", function () {
          $("#editdiv").removeClass("on");
          $(document).off("click");
          return false;
        });
      }, 0);
    });

    // Opening, Endingのトグルボタン
    $(".js-anime-toggle-op-ed-button").on("click", function () {
      $(".js-viewOpEd").slideToggle("fast");
      if ($(this).html().indexOf("More") > -1) {
        $(this).html("<i class=\"fa-solid fa-chevron-up mr4\"></i>Less theme songs");
      } else {
        $(this).html("<i class=\"fa-solid fa-chevron-down mr4\"></i>More theme songs");
      }
    });

    $(".js-anime-toggle-alternative-title-button").on("click", function () {
      $(".js-alternative-titles").slideToggle("fast");
      if ($(this).html().indexOf("More") > -1) {
        $(this).html("<i class=\"fa-solid fa-chevron-up mr4\"></i>Less titles");
      } else {
        $(this).html("<i class=\"fa-solid fa-chevron-down mr4\"></i>More titles");
      }
    });

    $(".js-anime-toggle-streaming-platform-button").on("click", function () {
      $(".js-streaming-platforms").slideToggle("fast");
      if ($(this).html().indexOf("More") > -1) {
        $(this).html("<i class=\"fa-solid fa-chevron-up mr4\"></i>Less services");
      } else {
        $(this).html("<i class=\"fa-solid fa-chevron-down mr4\"></i>More services");
      }
    });
  }

  // Statistics note
  if ($(".js-statistics-info")[0]) {
    $(".js-statistics-info").on("mouseover", function () {
      const id    = $(this).data("id");
      const $info = $(this).find(`.statistics-info.${id}`);
      const posV  = -1 * ($info.outerHeight() + 5);
      $info.show().css({top: posV});
    }).on("mouseout", function () {
      $(".statistics-info").hide();
    });
  }

  // btn filter event
  if ($(".js-search-filter-block")[0]) {

    const sortOrderList = {
      members   : "Members",
      start_date: "Newest",
      title     : "Title",
      score     : "Score",
      studio    : "Studio",
      licensor  : "Licensor"
    };

    const setCookie = function() {
      let selectedStatuses = $(".js-search-filter-mylist-block.js-sort .js-btn-sort-order.selected")
        .map(function(){ return $(this).attr("data-status"); }).toArray();
      let type = $(".search-filter-mylist-block").attr("data-type");
      Cookies.set("search_status_" + type, JSON.stringify(selectedStatuses));
    };

    const updateFiltered = function() {
      if ($(".js-search-filter-mylist-block.js-sort .js-btn-sort-order[data-status=all].selected").length > 0){
        $(".js-btn-show-mylist").removeClass("filtered");
      }else{
        $(".js-btn-show-mylist").addClass("filtered");
      }
    }

    const reloadFilter = function () {
      if ($(".js-search-filter-mylist-block.js-sort .js-ajax-loader")[0]) {
        $(".js-search-filter-mylist-block.js-sort .js-ajax-loader").show();
      }
      location.href = location.href.replace(/[#?].*$/, "")
    }

    //領域外クリックで閉じる
    const closeSortFilterDialog = function () {
      $(".js-search-filter-sort-order-block.js-sort").on("click", function (e) {
        e.stopPropagation();
      });
      $(".js-search-filter-mylist-block.js-sort").on("click", function (e) {
        e.stopPropagation();
      });
      $(document).on("click", function () {
        if($(".js-btn-show-mylist").hasClass("changed")) {
          reloadFilter();
          return false;
        }
        $(".js-search-filter-sort-order-block").hide();
        $(".js-search-filter-mylist-block").hide();
        $(".js-search-filter-block .js-btn-show-sort").removeClass("on");
        $(".js-search-filter-block .js-btn-show-mylist").removeClass("on");
        $(document).off("click");
        return false;
      });
    }

    //default
    let type = $(".search-filter-mylist-block").attr("data-type");
    const getSelectedID = Cookies.get("search_sort_anime") || "members";
    const selectedStatusesCookie = Cookies.get("search_status_" + type);
    let selectedStatuses;
    if (selectedStatusesCookie) {
      selectedStatuses = JSON.parse(selectedStatusesCookie);
    }else {
      selectedStatuses = $(".js-search-filter-mylist-block.js-sort .js-btn-sort-order")
        .map(function () {
          return $(this).attr("data-status");
        }).toArray();
    }

    $(".js-search-filter-block .js-text-sorted-by").text(sortOrderList[getSelectedID]);
    $(".js-search-filter-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
    $(`.js-search-filter-sort-order-block.js-sort .js-btn-sort-order#${getSelectedID}`).addClass("selected");
    for (var i = 0 ; i < selectedStatuses.length ; i++) {
      const status = selectedStatuses[i]
      $(`.js-search-filter-mylist-block.js-sort .js-btn-sort-order[data-status=${status}]`).addClass("selected");
    }

    updateFiltered();

    //onClick sortBy
    $(".js-search-filter-block .js-btn-show-sort").on("click", function () {
      if($(".js-btn-show-mylist").hasClass("changed")) {
        reloadFilter();
        return false;
      }
      $(this).toggleClass("on");
      $(".js-search-filter-sort-order-block").toggle();
      $(".js-search-filter-mylist-block").hide();
      $(".js-search-filter-block .js-btn-show-mylist").removeClass("on");
      closeSortFilterDialog();
      return false;
    });

    //onClick mylist
    $(".js-search-filter-block .js-btn-show-mylist").on("click", function () {
      $(this).toggleClass("on");
      $(".js-search-filter-mylist-block").toggle();
      $(".js-search-filter-sort-order-block").hide();
      $(".js-search-filter-block .js-btn-show-sort").removeClass("on");
      closeSortFilterDialog();
      return false;
    });

    //close
    $(".js-search-filter-block .js-search-filter-sort-order-block .js-btn-close").on("click", function () {
      $(".js-search-filter-block .js-btn-show-sort").toggleClass("on");
      $(".js-search-filter-sort-order-block").toggle();
    });

    $(".js-search-filter-block .js-search-filter-mylist-block .js-btn-close").on("click", function () {
      if($(".js-btn-show-mylist").hasClass("changed")) {
        reloadFilter();
        return false;
      }
      $(".js-search-filter-block .js-btn-show-mylist").toggleClass("on");
      $(".js-search-filter-mylist-block").toggle();
    });

    // sort ボタン
    $(".js-search-filter-sort-order-block.js-sort .js-btn-sort-order").on("click", function () {
      $(".js-search-filter-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $(this).addClass("selected");
      const id = $(this).attr("id");
      Cookies.set("search_sort_anime", id);

      //loader
      if ($(".js-search-filter-sort-order-block.js-sort .js-ajax-loader")[0]) {
        $(".js-search-filter-sort-order-block.js-sort .js-ajax-loader").show();
      }

      // remove query
      location.href = location.href.replace(/[#?].*$/, "")
    });

    $(".js-search-filter-mylist-block.js-sort .js-btn-sort-order").on("click", function () {
      const status = $(this).attr("data-status");
      if (status === "all") {
        if ($(this).hasClass("selected")) {
          $(".js-search-filter-mylist-block.js-sort .js-btn-sort-order[data-status!=all]").removeClass("selected");
        }else{
          $(".js-search-filter-mylist-block.js-sort .js-btn-sort-order[data-status!=all]").addClass("selected");
        }
      }else if ($(this).hasClass("selected")) {
        $(".js-search-filter-mylist-block.js-sort .js-btn-sort-order[data-status=all]").removeClass("selected");
      }
      $(this).toggleClass("selected");
      if ($(".js-search-filter-mylist-block.js-sort .js-btn-sort-order[data-status!=all].selected").length == 6){
        $(".js-search-filter-mylist-block.js-sort .js-btn-sort-order[data-status=all]").addClass("selected")
      }
      updateFiltered();
      setCookie();
      $(".js-btn-show-mylist").addClass("changed");
    });

    // 検索結果のlist表示のsort ボタン
    $(".js-block-list .js-btn-list-sort-order").on("click", function () {
      const id = $(this).attr("data-id");
      Cookies.set("search_sort_anime", id);

      // remove query
      location.href = location.href.replace(/\?.*$/, "");
    });

    $(".js-block-list2 .js-btn-list-sort-order").on("click", function () {
      const url = location.href.substring(0, location.href.indexOf('?'));
      window.location.href = url + '?sort=' + $(this).attr("data-id");
    });
  }

  $(".anime-search-filter .js-btn-sort-order").on("click", function(){
    if($(this).hasClass("selected")){
      $(this).addClass("crossed");
      $(this).removeClass("selected");
      $(this).children("input").attr({"name":"genre_ex[]"});
      $(this).children("input").prop("checked", true).change();
    }else if($(this).hasClass("crossed")){
      $(this).removeClass("crossed");
      $(this).children("input").prop("checked", false).change();
    }else{
      $(this).addClass("selected");
      $(this).children("input").attr({"name":"genre[]"});
      $(this).children("input").prop("checked", true).change();
    }
  });

  $(".anime-search-filter .clear-all-btn").on("click", function(){
    $(".anime-search-filter .selected").removeClass("selected");
    $(".anime-search-filter .crossed").removeClass("crossed");
    $(".anime-search-filter input").prop("checked", false).change();
  })

  $(".anime-search-filter .show-all-btn").on("click", function(){
    $(this).siblings(".btn-sort-order").css("display","block");
    $(this).css("display","none");
  })


  if ($(".js-btn-anime-episode-bing")[0]) {
    $(".js-btn-anime-episode-bing").on("click", function () {
      return false;
    });
  }

  $(".js-left-info-block-button-side").on("click", function () {
    const flg = $(this).hasClass("on");
    if (flg === false) {
      //side open
      $(".js-left-info-block").addClass("on");
      $(".js-left-info-block-inner").addClass("on");
      $(".js-bing-result-block").addClass("on");
    } else {
      //side close
      $(".js-left-info-block").removeClass("on");
      $(".js-left-info-block-inner").removeClass("on");
      $(".js-bing-result-block").removeClass("on");
    }
    $(this).toggleClass("on");
  });


  if ($(".btn-eps-num-dot")[0]) {
    //for safari browser
    const getBrowserName = function () {
      const ua   = window.navigator.userAgent.toLowerCase();
      let name = "";
      if (ua.indexOf("msie") !== -1) {
        name = "ie";
      } else if (ua.indexOf("trident/7") !== -1) {
        name = "ie";
      } else if (ua.indexOf("chrome") !== -1) {
        name = "chrome";
      } else if (ua.indexOf("safari") !== -1) {
        name = "safari";
      } else if (ua.indexOf("opera") !== -1) {
        name = "opera";
      } else if (ua.indexOf("firefox") !== -1) {
        name = "firefox";
      }
      return name;
    }
    if (getBrowserName() === "safari") {
      $(".btn-eps-num-dot").css({padding: "0px 0px 0px 8px"})
    }
  }

  if ($(".js-btn-eps-watched")[0]) {
    $(".js-btn-eps-watched").on("mouseover", function () {
      if (!$(this).hasClass("watched")) {
        const epsVal = $(".js-bing-result-control-block").attr("data-episode-id");
        $(".js-add-eps-num").text(epsVal);
        $(".js-add-eps-num").addClass("on");
      }
    }).on("mouseout", function () {
      $(".js-add-eps-num").removeClass("on");
    });

    $(".js-btn-eps-watched").on("click", function () {
      if (!$(this).hasClass("watched")) {
        const animeID  = $(".js-bing-result-control-block").data("anime");
        const epsVal   = parseInt($(".js-bing-result-control-block").attr("data-episode-id"));
        let epsTotal = $(".js-btn-eps-num-latest").data("number");
        epsTotal = epsTotal ? epsTotal : 9999;
        if (epsTotal > epsVal) {
          /* eslint-disable camelcase */
          $.ajax({
            url     : "/animelist/?update=1",
            type    : "POST",
            data    : {
              id                   : animeID,
              completed_episode_num: epsVal,
              auto_status          : 1
            },
            datatype: "html",
            timeout : 10000,
            cache   : false,
            success : function (XMLHttpRequest, textStatus, errorThrown) {
              $(".js-btn-eps-watched").addClass("watched");
              if (errorThrown.responseText === "This anime has not been approved yet.") {
                console.error(errorThrown.responseText);
              }
            },
            error   : function (XMLHttpRequest, textStatus, errorThrown) {
              if (errorThrown.responseText === "This anime has not been approved yet.") {
                console.error(errorThrown.responseText);
              }
            },
          });
          /* eslint-enable camelcase */
        }
      }
    });
  }

  if ($(".js-watch-episode-list-short")[0]) {
    $(".title-text").each(function () {
      const textW = $(this).width();
      if ($(this).parent("a").hasClass("new")) {
        if (textW >= 310) {
          $(this).parent("a").next(".icon-new").css({right: 0});
          $(this).parent("a").addClass("overwidth");
        } else {
          $(this).parent("a").next(".icon-new").css({left: 24 + textW});
        }
        const url = $(this).parent("a").attr("href");
        $(this).parent("a").next(".icon-new").on("click", function () {
          location.href = url;
        });
      }
    });
  }

  if ($(".js-watch-episode-list")[0]) {
    $('.js-watch-episode-list').on('sortchange', function() {
      const plist = $('.js-watch-episode-list tbody tr').clone(true);
      const psortKey = $('.js-watch-episode-list').data('sort-key');
      const psortOrder = $('.js-watch-episode-list').data('sort-order');
      plist.sort(function (a, b) {
        const val_a = $(`td.${psortKey}`, a).data('raw');
        const val_b = $(`td.${psortKey}`, b).data('raw');
        // 逆順ソート
        if (psortOrder == 'descend') {
          if (val_a < val_b) {
            return 1;
          }
          if (val_a > val_b) {
            return -1;
          }
          return 0;
        }
        // 昇順ソート
        if (val_a < val_b) {
          return -1;
        }
        if (val_a > val_b) {
          return 1;
        }
        return 0;
      })
      $('.js-watch-episode-list tbody').empty().append(plist);
    });

    $('.js-btn-sort').on('click', function () {
      const psortKey = $(this).parent().data('sort-key');
      const ptable = $('.js-watch-episode-list');
      $('.js-btn-sort').removeClass('fa-sort-up fa-sort-down');
      $('.js-btn-sort').addClass('fa-sort');
      $(this).removeClass('fa-sort');

      if (ptable.data('sort-key') == psortKey) {
        if (ptable.data('sort-order') == 'ascend') {
          ptable.data('sort-order', 'descend');
          $(this).addClass('fa-sort-down');
        } else {
          ptable.data('sort-order', 'ascend');
          $(this).addClass('fa-sort-up');
        }
      } else {
        ptable.data('sort-key', psortKey);
        ptable.data('sort-order', 'ascend');
        $(this).addClass('fa-sort-up');
      }

      ptable.trigger('sortchange');
    })
  }

  //狭い画面画面の時にロードしたら上にスクロール
  if ($("#episode-watch")[0]) {
    const winH = (window.innerHeight || document.documentElement.clientHeight || 0);
    if (winH < 500) {
      const posV = $("#episode-watch").offset().top;
      $("html,body").animate({scrollTop: posV});
    }
  }

  //-----------------------
  //video
  //-----------------------
  if ($(".js-fancybox-video")[0]) {
    $(".js-fancybox-video").attr("rel", "gallery").fancybox({
      openEffect   : "none",
      closeEffect  : "none",
      nextEffect   : "none",
      prevEffect   : "none",
      padding      : 0,
      margin       : 50,
      showNavArrows: false,
      keys         : {
        next  : {
          13: "left", // enter
          34: "up",   // page down
          39: "left", // right arrow
          40: "up"    // down arrow
        },
        prev  : {
          8 : "right",  // backspace
          33: "down",   // page up
          37: "right",  // left arrow
          38: "down"    // up arrow
        },
        close : [27], // escape key
        play  : [32], // space - start/stop slideshow
        toggle: [70]  // letter "f" - toggle fullscreen
      },
      width        : "80%",
      height       : "80%",
      beforeShow   : function () {
        // Find the iframe ID
        const id = $.fancybox.inner.find("iframe").attr("id");

        // Create video player object and add event listeners
        const player = new YT.Player(id, { // eslint-disable-line no-unused-vars
          events: {
            onReady      : onPlayerReady,
            onStateChange: onPlayerStateChange
          }
        });
      },
      // for analyze
      onComplete   : function () {
        Log.actionLog("promotion_video", {
          id      : this.orig[0].dataset.videoId,
          anime_id: this.orig[0].dataset.animeId, // eslint-disable-line camelcase
          title   : this.orig[0].dataset.title
        });
      }
    });
  }

  if ($(".js-btn-video-category")[0]) {
    $(".js-btn-video-category").click(function () {
      const id  = $(this).data("id");
      const pos = $(`#${id}`).offset().top;
      $("html,body").animate({scrollTop: (pos - 70)});
      return false;
    });
  }

  if ($(".js-btn-video-type")[0]) {
    $(".js-btn-video-type").click(function () {
      if (!$(this).hasClass("on")) {
        const url       = $(this).data("url");
        location.href = url;
        return false;
      }
    });
  }

  // Episode list
  if ($(".js-episode-vote-button")[0]) {
    $(".js-episode-vote-button").on("click", function() {
      Modal.generate();
      Modal.buildVoteDialog(
        $(this).data('title-id'),
        $(this).data('episode-num'),
        $('.title-name').text(),
        $(this).data('topic-id'),
        1,
        false
      );
      Modal.show();
    })
  }

  // episode detail - provider
  if ($(".js-btn-select-provider")[0]) {
    $(".js-btn-select-provider").on("click", function () {
      $(".js-select-provider").toggle();
    });
  }

  // episode detail - Status
  if ($(".js-user-status-block")[0]) {

    // 左カラム以外の myinfo_addtolist()
    const myinfoAddtolist = function (isShowEditStatus) {
      if ($(".js-user-status-block").data("type") === "manga") {
        // TODO: マンガ関連の処理はmanga.jsに移動したい
        // manga add
        const mangaId = $(".js-user-status-block #myinfo_anime_id").val();
        const score   = $(".js-user-status-block #myinfo_score").val();
        const status  = $(".js-user-status-block #myinfo_status").val() || $(".js-user-status-block #myinfo_status").data("value");

        const chapters = $(".js-user-status-block #myinfo_chapters").val();
        const volumes  = $(".js-user-status-block #myinfo_volumes").val();

        $(".js-user-status-block").addClass("on");
        $(".js-myinfo-error").html("").hide();

        List.addMangaEntry(mangaId, {
          status  : status,
          score   : score,
          chapters: chapters,
          volumes : volumes,
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block .js-form-user-status-btn").remove();
            $(".js-user-status-block .js-form-user-status-select").prop("disabled", false).show();
            $(".js-user-status-block .js-form-user-status-select").val(status);
            setUserStatusColor();

            // 左カラムの成功表示
            if (isShowEditStatus) {
                $(".js-manga-addtolist-block #myinfo_status").val(status);
                $(".js-manga-addtolist-block #myinfo_score").val(score);
                $(".js-manga-addtolist-block #myinfo_chapters").val(chapters);
                $(".js-manga-addtolist-block #myinfo_volumes").val(volumes);
                $("#addtolist").slideDown();
            }else{
                renderSuucessMangaLeftColumn();
            }
          },
          onFail(error) {
            $(".js-user-status-block").removeClass("on");
            $(".js-myinfo-error").text(error).show();
          },
        });
      } else {
        // anime add
        const animeId  = $("#myinfo_anime_id").val();
        const score    = $(".js-user-status-block #myinfo_score").val();
        const status   = $(".js-user-status-block #myinfo_status").val() || $(".js-user-status-block #myinfo_status").data("value");
        const episodes = $(".js-user-status-block #myinfo_watchedeps").val();

        $(".js-user-status-block").addClass("on");
        $(".js-myinfo-error").html("").hide();

        List.addAnimeEntry(animeId, {
          status  : status,
          score   : score,
          episodes: episodes,
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");
            $(".js-user-status-block .js-form-user-status-btn").remove();
            $(".js-user-status-block .js-form-user-status-select").prop("disabled", false).show();
            $(".js-user-status-block .js-form-user-status-select").val(status);
            setUserStatusColor();
            // 左カラムの成功表示
              if (isShowEditStatus) {
                  $(".js-anime-addtolist-block #myinfo_status").val(status);
                  $(".js-anime-addtolist-block #myinfo_score").val(score);
                  $(".js-anime-addtolist-block #myinfo_watchedeps").val(episodes);
                  $("#addtolist").slideDown();
              }else{
                  renderSuucessLeftColumn(animeId);
              }
          },
          onFail(error) {
            $(".js-user-status-block").removeClass("on");
            $(".js-myinfo-error").text(error).show();
          },
        });
      }
    }


    const myinfoUpdateInfo = function (reason) {
      const isTmpUser = $('.js-user-status-block').data('is_tmp_user');
      if ($(".js-user-status-block").data("type") === "manga") {
        // manga update
        const mangaId  = $(".js-user-status-block #myinfo_anime_id").val();
        const score    = $(".js-user-status-block #myinfo_score").val();
        const status   = $(".js-user-status-block #myinfo_status").val();
        const chapters = $(".js-user-status-block #myinfo_chapters").val();
        const volumes  = $(".js-user-status-block #myinfo_volumes").val();

        $(".js-user-status-block").addClass("on");
        $(".js-myinfo-error").html("").hide();

        List.updateMangaEntry(mangaId, {
          status  : status,
          score   : score,
          chapters: chapters,
          volumes : volumes,
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");

            // 左カラムと同期
            $(".js-manga-addtolist-block #myinfo_status").val(status);
            $(".js-manga-addtolist-block #myinfo_score").val(score);
            $(".js-manga-addtolist-block #myinfo_chapters").val(chapters);
            $(".js-manga-addtolist-block #myinfo_volumes").val(volumes);
            // +1ダイアログ表示
            if (reason == 'episode' && isTmpUser === undefined) {
              PlusOne.showMangaVoteDialog(mangaId, chapters);
            }
          },
          onFail() {
            $(".js-user-status-block").removeClass("on");
            $(".js-myinfo-error").innerHTML = "Failed to Update";
          },
        });
      } else {
        // anime update
        const animeId  = $(".js-user-status-block #myinfo_anime_id").val();
        const score    = $(".js-user-status-block #myinfo_score").val();
        const status   = $(".js-user-status-block #myinfo_status").val();
        const episodes = $(".js-user-status-block #myinfo_watchedeps").val();

        $(".js-user-status-block").addClass("on");
        $(".js-myinfo-error").html("").hide();

        List.updateAnimeEntry(animeId, {
          status  : status,
          score   : score,
          episodes: episodes,
        }, {
          onSuccess() {
            $(".js-user-status-block").removeClass("on");

            // 左カラムと同期
            $(".js-anime-addtolist-block #myinfo_status").val(status);
            $(".js-anime-addtolist-block #myinfo_score").val(score);
            $(".js-anime-addtolist-block #myinfo_watchedeps").val(episodes);
            // +1ダイアログ表示
            if (reason == 'episode' && isTmpUser === undefined) {
              PlusOne.showAnimeVoteDialog(animeId, episodes);
            }
          },
          onFail() {
            $(".js-user-status-block").removeClass("on");
            $(".js-myinfo-error").innerHTML = "Failed to Update";
          },
        });
      }
    }

    const check3episodes = function (watchedeps) {
      if ($(".js-user-status-block").data("type") === "manga") return;
      const $review_form = $('#review_form');
      const $review_msg  = $('#review_message');
      const $onaired_episode = $('#onaired_episode');
      const $media_type  = $('#myinfo_media_type');
      if ( $media_type.val() == 1 ) {
        if ($onaired_episode.length) {
          if( watchedeps < 3 ) {
            $onaired_episode[0].style.display = 'none';
          } else {
            $onaired_episode[0].style.display = 'block';
          }
        }
        if ($review_msg.length) {
          if( watchedeps < 3 ) {
            $review_msg[0].style.display = 'block';
          } else {
            $review_msg[0].style.display = 'none';
          }
        }
        if ($review_form.length) {
          $('#seeneps').val(watchedeps);
          if( watchedeps < 3 ) {
            $review_form[0].style.display = 'none';
          } else {
            $review_form[0].style.display = 'block';
          }
        }
      } else { // TV以外
        if ($review_msg.length) {
          if( watchedeps < 1 ) {
            $review_msg[0].style.display = 'block';
          } else {
            $review_msg[0].style.display = 'none';
          }
        }
        if ($review_form.length) {
          $('#seeneps').val(watchedeps);
          if( watchedeps < 1 ) {
            $review_form[0].style.display = 'none';
          } else {
            $review_form[0].style.display = 'block';
          }
        }
      }
    }
    window['check3episodes'] = check3episodes;

    const checkUserStatus = function () {
      const $status = $(".js-user-status-block .js-form-user-status");
      const text    = $status.find("option:selected").text().toLowerCase().replace(/\s+/g, "");
      return text;
    }

    const pushUserStatus = function (reason) {
      const $formUserStatus = $(".js-user-status-block .js-form-user-status");
      if ($formUserStatus.hasClass("myinfo_addtolist")) {
        myinfoAddtolist();
        $formUserStatus.removeClass("myinfo_addtolist").addClass("myinfo_updateInfo");
      } else if ($formUserStatus.hasClass("myinfo_updateInfo")) {
        myinfoUpdateInfo(reason);
      }
    }

    // status color
    const setUserStatusColor = function () {
      const statusText = checkUserStatus();
      $(".js-user-status-block .js-form-user-status").attr("data-class", statusText);
    }

    const checkUserStatusCompleted = function () {
      const $userEpisode = $(".js-user-status-block .form-user-episode");

      if ($(".js-user-status-block").data("type") === "manga") {
        //manga
        const volTotal = parseInt($(".js-user-status-block #totalVols").text());
        const volRead  = parseInt($(".js-user-episode-seen#myinfo_volumes").val() || 0);
        if ("completed" === checkUserStatus() && volTotal <= volRead && volTotal !== "") {
          $userEpisode.addClass("completed");
        } else {
          $userEpisode.removeClass("completed");
        }

        const chapTotal = parseInt($(".js-user-status-block #totalChaps").text());
        const chapRead  = parseInt($(".js-user-episode-seen#myinfo_chapters").val() || 0);
        if ("completed" === checkUserStatus() && chapTotal <= chapRead && chapTotal !== "") {
          $userEpisode.addClass("completed");
        } else {
          $userEpisode.removeClass("completed");
        }

      } else {
        //anime
        const epsTotal = parseInt($(".js-user-status-block #curEps").text());
        const epsSeen  = parseInt($(".js-user-episode-seen#myinfo_watchedeps").val() || 0);
        if ("completed" === checkUserStatus() && epsTotal <= epsSeen && epsTotal !== "") {
          $userEpisode.addClass("completed");
        } else {
          $userEpisode.removeClass("completed");
        }
      }
    }
    checkUserStatusCompleted();

    // status
    if ($(".js-user-status-block .js-form-user-status")[0]) {
      setUserStatusColor();//onload

      $(".js-user-status-block  .js-form-user-status-btn").on("click", function () {
        pushUserStatus();
      });


      $(".js-user-status-block .js-form-user-status").on("change", function () {
        const statusText = checkUserStatus();
        $(".js-user-status-block .js-form-user-status").attr("data-class", statusText);

        if ($(".js-user-status-block").data("type") === "manga") {
          //manga
          const volTotal = parseInt($(".js-user-status-block #totalVols").text());
          // fill Eps num if status is completed
          if (statusText === "completed" && volTotal) {
            $(".js-user-episode-seen#myinfo_volumes").val(volTotal).change();
          }

          const chapTotal = parseInt($(".js-user-status-block #totalChaps").text());
          // fill Eps num if status is completed
          if (statusText === "completed" && chapTotal) {
            $(".js-user-episode-seen#myinfo_chapters").val(chapTotal).change();
          }
        } else {
          //anime
          const epsTotal = parseInt($(".js-user-status-block #curEps").text());
          // fill Eps num if status is completed
          if (statusText === "completed" && epsTotal) {
            $(".js-user-episode-seen#myinfo_watchedeps").val(epsTotal).change();
            check3episodes(epsTotal);
          }
        }

        checkUserStatusCompleted();
        setUserStatusColor();
        pushUserStatus();
      });

      if ($(".mal-btn")[0]) {
          $('.mal-btn').click(function () {
              var status = $(this).data('status');
              $('.user-status-block #myinfo_status').attr('data-value', status);
              if (status == 2) {
                  if ($(".js-user-status-block").data("type") === "manga") {
                      var maxVols = $(".user-status-block #totalVols").data('num');
                      $(".user-status-block #myinfo_volumes").val(maxVols);
                      var maxChaps = $(".user-status-block #totalChaps").data('num');
                      $(".user-status-block #myinfo_chapters").val(maxChaps);
                  }else {
                      var max = $(".user-status-block #curEps").data('num');
                      $(".user-status-block #myinfo_watchedeps").val(max);
                  }
              }
              myinfoAddtolist(true);
              $('mal-tooltip').remove();
          });
      }
    }

    // Score
    if ($(".js-user-status-block .js-form-user-score")[0]) {
      $(".js-user-status-block .js-form-user-score").on("change", function () {
        pushUserStatus();
      });
    }
    // Score for free manga
    if ($(".js-user-status-block .js-form-user-score-1")[0]) {
      $(".js-user-status-block .js-form-user-score-1").on("change", function () {
        pushUserStatus();
        $(".btn-user-status-add-list").remove();
      });
    }

    // Episode Seen
    if ($(".js-user-status-block .js-btn-count")[0]) {
      const $userEpisode = $(".js-user-status-block .form-user-episode");
      if (!$userEpisode.hasClass("disabled")) {
        const countUpDown = function (obj, btnID, totalID) {
          const $myinfoWatchedeps = $(`.js-user-status-block #${btnID}`);
          const nepsseen           = parseInt($myinfoWatchedeps.val() || 0);
          let curEps             = $(`.js-user-status-block #${totalID}`).data("num");
          curEps = parseInt(curEps) || 0;

          if (!$userEpisode.hasClass("completed") || curEps === 0) {
            const countUpEpsNum = function (num) {
              $myinfoWatchedeps.val(nepsseen + num).trigger("change");
              pushUserStatus('episode');
              check3episodes(nepsseen + num);
            }
            if (obj.hasClass("increase")) {
              if (curEps === 0 || nepsseen < curEps) {
                countUpEpsNum(1);
              }
            }
            if (obj.hasClass("decrease")) {
              if (0 < nepsseen) {
                countUpEpsNum(-1);
              }
            }
          }
        }

        //button
        $(".js-btn-count").on("click", function () {
          // Clear preparing plus one dialog
          PlusOne.clearVoteDialog();
          if ($(this).hasClass("volume")) {
            //manga volume
            countUpDown($(this), "myinfo_volumes", "totalVols");
          } else if ($(this).hasClass("chapter")) {
            //manga chapter
            countUpDown($(this), "myinfo_chapters", "totalChaps");
          } else {
            //anime
            countUpDown($(this), "myinfo_watchedeps", "curEps");
          }
        });


        // score input
        const inputEpsNum = function (str, totalID) {
          let curEps = $(".js-user-episode-seen").next(`#${totalID}`).data("num");
          curEps = parseInt(curEps) || 0;
          if (str.match(/^[-]?[0-9]+(¥.[0-9]+)?$/)) {
            if (parseInt(str) <= parseInt(curEps) || curEps === 0) {
              pushUserStatus('episode');
              $(".js-user-episode-seen").blur();
            }
          }
        }

        $(".js-user-episode-seen").on("focus", function () {
          $(this).attr("data-eps", $(this).val());
        });

        $(".js-user-episode-seen").on("keyup", function (e) {
          if (!$userEpisode.hasClass("completed")) {
            const keycode = e.keyCode;
            switch (keycode) {
              case 27://esc
                const originNum = $(this).attr("data-eps");
                $(this).val(originNum).blur();
                break;
              case 8://backspace
                break;
              default:
                const str     = $(this).val();
                const totalID = $(this).next("span").attr("id");
                setTimeout(function () {
                  inputEpsNum(str, totalID);
                }, 500);
                break;
            }
          }
        });
      }
    }
    // Episode Seen for free manga
    if ($(".js-user-status-block .js-btn-count-1")[0]) {
      const $userEpisode = $(".js-user-status-block .form-user-episode");
      if (!$userEpisode.hasClass("disabled")) {
        const countUpDown = function (obj, btnID, totalID) {
          const $myinfoWatchedeps = $(`.js-user-status-block #${btnID}`);
          const nepsseen           = parseInt($myinfoWatchedeps.val() || 0);
          let curEps             = $(`.js-user-status-block #${totalID}`).data("num");
          curEps = parseInt(curEps) || 0;

          if (!$userEpisode.hasClass("completed") || curEps === 0) {
            const countUpEpsNum = function (num) {
              $myinfoWatchedeps.val(nepsseen + num);
              pushUserStatus('episode');
              $(".btn-user-status-add-list").remove();
            }
            if (obj.hasClass("increase")) {
              if (curEps === 0 || nepsseen < curEps) {
                countUpEpsNum(1);
              }
            }
            if (obj.hasClass("decrease")) {
              if (0 < nepsseen) {
                countUpEpsNum(-1);
              }
            }
          }
        }

        //button
        $(".js-btn-count-1").on("click", function () {
          if ($(this).hasClass("volume")) {
            //manga volume
            countUpDown($(this), "myinfo_volumes", "totalVols");
          } else if ($(this).hasClass("chapter")) {
            //manga chapter
            countUpDown($(this), "myinfo_chapters", "totalChaps");
          } else {
            //anime
            countUpDown($(this), "myinfo_watchedeps", "curEps");
          }
        });


        // score input
        const inputEpsNum = function (str, totalID) {
          let curEps = $(".js-user-episode-seen").next(`#${totalID}`).data("num");
          curEps = parseInt(curEps) || 0;
          if (str.match(/^[-]?[0-9]+(¥.[0-9]+)?$/)) {
            if (parseInt(str) <= parseInt(curEps) || curEps === 0) {
              pushUserStatus();
              $(".btn-user-status-add-list").remove();
              $(".js-user-episode-seen").blur();
            }
          }
        }

        $(".js-user-episode-seen").on("focus", function () {
          $(this).attr("data-eps", $(this).val());
        });

        $(".js-user-episode-seen").on("keyup", function (e) {
          if (!$userEpisode.hasClass("completed")) {
            const keycode = e.keyCode;
            switch (keycode) {
              case 27://esc
                const originNum = $(this).attr("data-eps");
                $(this).val(originNum).blur();
                break;
              case 8://backspace
                break;
              default:
                const str     = $(this).val();
                const totalID = $(this).next("span").attr("id");
                setTimeout(function () {
                  inputEpsNum(str, totalID);
                }, 500);
                break;
            }
          }
        });
      }
    }
  }

  if ($(".js-btn-video-anime-slide")[0]) {
    const animeId             = MAL.episodeVideo.aroundVideos.animeId;
    const episodeNumber       = MAL.episodeVideo.aroundVideos.episodeNumber;
    let pager               = MAL.episodeVideo.aroundVideos.pager;
    const fetchedPage         = {};
    fetchedPage[pager.page] = pager;
    const slide_number        = $(".js-video-anime-slide-outer").data("slide") || 5;
    const $slideOuter         = $(".js-video-anime-slide-outer");
    const $btnAnime           = $slideOuter.find(".btn-anime");
    const btnAnimeH           = $btnAnime.outerHeight();
    const btnAnimeW           = $btnAnime.outerWidth();

    let $slideInner;

    const aroundEpisodes = new sVue({
      el      : "#vue-video-slide",
      data    : {
        videos: MAL.episodeVideo.aroundVideos.videos
      },
      // 現在のepisdoeが表示されるように初期の位置をズラす
      mounted: function () {
        // Vue内のDOM要素はdocumentにappendした後に
        // jQueryオブジェクトにしないとpositionとか使えない
        $slideInner = $(".js-video-anime-slide");
        const index     = _.indexOf(_.map(this.$data.videos, function (video) {
          return video.episode_number;
        }), episodeNumber);
        const page      = Math.floor(index / slide_number);
        const $btnAnime = $slideOuter.find(".btn-anime");

        if ($(".js-btn-video-anime-slide").hasClass("hori")) {//横
          const btnAnimeW      = $btnAnime.outerWidth() + parseInt($btnAnime.css("marginRight"));
          const resetLeft      = btnAnimeW * page * slide_number * -1;
          const btnAnimeTotalW = btnAnimeW * $btnAnime.length;
          $slideInner.css({left: resetLeft});
        } else { //縦
          const btnAnimeH = $btnAnime.outerHeight();
          const resetTop  = btnAnimeH * page * slide_number * -1;
          $slideInner.css({top: resetTop});
        }
      },
    });

    //縦スクロール
    const scrollVideoVert = function (direction) {
      const posV      = $slideInner.position().top;
      const $btnAnime = $slideOuter.find(".btn-anime");
      const btnAnimeH = $btnAnime.outerHeight();
      let moveVal   = direction * btnAnimeH * slide_number + posV;

      if (moveVal > 0) {
        moveVal = 0;
      }

      const maxH = -1 * (btnAnimeH * $btnAnime.length - $slideOuter.height() - 1);
      if (maxH > moveVal) {
        moveVal = maxH;
      }

      // 上下にスクロールできなくなったらデータを取得
      if (moveVal == posV) {
        loadVideo(direction);
      }
      else {
        $slideInner.animate({top: moveVal});
      }
    }

    //横スクロール
    const scrollVideoHori = function (direction) {
      const posH       = $slideInner.position().left;
      const $btnAnime  = $slideOuter.find(".btn-anime");
      const btnAnimeMR = parseInt($btnAnime.css("marginRight"))
      const btnAnimeW  = $btnAnime.outerWidth() + btnAnimeMR;
      let moveVal    = direction * btnAnimeW * slide_number + posH;


      if (moveVal > 0) {
        moveVal = 0;
      }
      const maxW = -1 * (btnAnimeW * $btnAnime.length - $slideOuter.width() - btnAnimeMR);
      if (maxW > moveVal) {
        moveVal = maxW;
      }

      // 左右にスクロールできなくなったらデータを取得
      if (moveVal === posH) {
        loadVideo(direction, true);
      }
      else {
        $slideInner.animate({left: moveVal});
      }
    }


    $(".js-btn-video-anime-slide").on("click", function (e) {
      if ($(this).hasClass("hori")) {// 横スクロールの場合
        var direction = $(this).data("dir");
        direction = (direction === "next") ? -1 : 1;
        scrollVideoHori(direction);
      } else {// 縦スクロールの場合
        var direction = $(this).data("dir");
        direction = (direction === "next") ? -1 : 1;
        scrollVideoVert(direction);
      }
    });

    const getMinPager = function () {
      const minPage = _.min(_.keys(fetchedPage));
      return fetchedPage[minPage];
    }

    const getMaxPager = function () {
      const maxPage = _.max(_.keys(fetchedPage));
      return fetchedPage[maxPage];
    }

    const loadVideo = function (direction, isHorizon) {
      let page;
      if (direction > 0) {
        pager = getMinPager();
        if (!pager.hasPrevPage) {
          return;
        }
        page = pager.prevPage;
      }
      else {
        pager = getMaxPager();
        if (!pager.hasNextPage) {
          return;
        }
        page = pager.nextPage;
      }
      if (fetchedPage[page]) {
        return;
      }

      const $loading = $slideOuter.find(".video-loading");
      $loading.show();
      $.ajax({
        url     : "/anime/api/video/around.json",
        type    : "GET",
        data    : {
          id: animeId,
          p : page
        },
        dataType: "json",
        success : function (data) {
          let args;
          if (direction > 0) {
            args = [0, 0].concat(data.videos);
          }
          else {
            const last = aroundEpisodes.videos.length;
            args = [last, last].concat(data.videos);
          }
          aroundEpisodes.videos.splice.apply(aroundEpisodes.videos, args);
          fetchedPage[page] = data.pager;

          // dataを変更するタイミングをDOMへの反映は同期していない
          sVue.nextTick(function () {
            const $btnAnime = $slideOuter.find(".btn-anime");
            // 前に要素を追加するので位置がずれないように見せる
            if (direction > 0) {
              if (isHorizon) {
                const btnAnimeW = $btnAnime.outerWidth();
                const posH      = $slideInner.position().left;
                const resetLeft = posH + data.videos.length * btnAnimeW * -1;
                $slideInner.css({left: resetLeft});
              }
              else {
                const btnAnimeH = $btnAnime.outerHeight();
                const posV      = $slideInner.position().top;
                const resetTop  = posV + data.videos.length * btnAnimeH * -1;
                $slideInner.css({top: resetTop});
              }
            }
            if (isHorizon) {
              scrollVideoHori(direction);
            }
            else {
              scrollVideoVert(direction);
            }
          })
          $loading.hide();
        }
      });

    }

  }

  //-----------------------
  //video Slider
  //-----------------------
  const animeSlider = function (selectorName, btnWidth, slideMargin) {
    const $animeSlideBlock = $(`#${selectorName}.anime-slide-block`);

    if (!$animeSlideBlock.length) {
      return;
    }

    const option      = $animeSlideBlock.data("json");
    var btnWidth    = btnWidth || option.btnWidth;
    var slideMargin = slideMargin || option.margin;

    $animeSlideBlock.css({width: option.width});
    $animeSlideBlock.find(".anime-slide-outer").css({width: option.width});
    $animeSlideBlock.find(".anime-slide").css({width: option.width});

    const moveSlideNum = $animeSlideBlock.find(".js-anime-slide").data("slide") || 4;//How many move

    const animeW     = $animeSlideBlock.find(".anime-slide li.btn-anime").eq(0).outerWidth();
    const animeCount = $animeSlideBlock.find(".anime-slide li.btn-anime").length;//count total Anime
    const animeWidth = animeW + slideMargin;

    const setSlideWidth = animeWidth * (animeCount + moveSlideNum);//Slider Outer Width
    $animeSlideBlock.find(".anime-slide").width(setSlideWidth);

    //button
    const $sliderBtnLeft  = $animeSlideBlock.find(".btn-anime-slide-side.left");
    const $sliderBtnRight = $animeSlideBlock.find(".btn-anime-slide-side.right");

    // hide button
    if (animeCount < moveSlideNum + 1) {
      $sliderBtnLeft.hide();
      $sliderBtnRight.hide();
    } else if (!hasTouchEvent) {
      const hideBtnTimer = setInterval(function () {
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
        clearInterval(hideBtnTimer);
      }, 1500);
    }

    //click
    $sliderBtnLeft.find(".btn-inner").on("click", function (e) {
      const btn = {
        direction: "left",
        button   : $(this)
      };
      moveSlideAnime(btn);
    });
    $sliderBtnRight.find(".btn-inner").on("click", function (e) {
      const btn = {
        direction: "right",
        button   : $(this)
      };
      moveSlideAnime(btn);
    })

    //hover
    if (!hasTouchEvent) {
      $animeSlideBlock.find(".anime-slide-outer").on("mouseover", function () {
        $sliderBtnLeft.css({left: 0, opacity: 1});
        $sliderBtnRight.css({right: 0, opacity: 1});
      }).on("mouseout", function () {
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
      });

      $sliderBtnLeft.on("mouseover", function () {
        $sliderBtnLeft.css({left: 0, opacity: 1});
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
      }).on("mouseout", function () {
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
      });

      $sliderBtnRight.on("mouseover", function () {
        $sliderBtnRight.css({right: 0, opacity: 1});
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
      }).on("mouseout", function () {
        $sliderBtnRight.css({right: -1 * btnWidth, opacity: 0});
        $sliderBtnLeft.css({left: -1 * btnWidth, opacity: 0});
      });
    }

    // slide function
    const moveSlideAnime = function (obj) {
      const direction = obj.direction;
      const $btnInner = obj.button;
      $btnInner.hide();

      if (direction == "right") {
        var animeSlide = [];
        $animeSlideBlock.find(".anime-slide li.btn-anime").each(function () {
          animeSlide.push($(this));
        });

        for (var i = 0; i < moveSlideNum; i++) {
          animeSlide[i].clone(true).insertAfter($animeSlideBlock.find(".anime-slide li.btn-anime:last"));
        }

        $animeSlideBlock.find(".anime-slide").css("marginLeft", "0px");

        $animeSlideBlock.find(".anime-slide").animate({
          marginLeft: `${-1 * animeWidth * moveSlideNum}px`
        }, {
          duration: 500,
          easing  : "swing",
          complete: function () {
            const getMarginLeftVal = parseInt($animeSlideBlock.find(".anime-slide").css("marginLeft").replace("px", ""));
            for (let i = 0; i < moveSlideNum; i++) {
              $animeSlideBlock.find(".anime-slide").css("marginLeft", `${getMarginLeftVal + animeWidth * moveSlideNum}px`);
              $animeSlideBlock.find(".anime-slide li.btn-anime:first").remove();
            }
            $btnInner.show();
          }
        });
      } else {
        var animeSlide = [];
        $animeSlideBlock.find(".anime-slide li.btn-anime").each(function () {
          animeSlide.push($(this));
        });
        animeSlide.reverse();

        for (var i = 0; i < moveSlideNum; i++) {
          animeSlide[i].clone(true).insertBefore($animeSlideBlock.find(".anime-slide li.btn-anime:first"));
        }

        const marginLeftVal = `${-1 * animeWidth * moveSlideNum}px`;
        $animeSlideBlock.find(".anime-slide").css("marginLeft", marginLeftVal);

        $animeSlideBlock.find(".anime-slide").animate({
          marginLeft: "0px"
        }, {
          duration: 500,
          easing  : "swing",
          complete: function () {
            for (let i = 0; i < moveSlideNum; i++) {
              $animeSlideBlock.find(".anime-slide li.btn-anime:last").remove();
            }
            $btnInner.show();
          }
        });
      }
    };
  }

  if ($(".anime-slide-block")[0]) {
    const slideCount = $(".anime-slide-block").length;
    for (let i = 0; i < slideCount; i++) {
      const id = $(".anime-slide-block").eq(i).attr("id");
      animeSlider(id);
    }
  }

  // amazon affiliate
  if ($(".js-shop-selecter")[0]) {
    $(".js-shop-selecter").on("mouseenter", function() {
      $(".js-shop-selecter-dialog").fadeIn()
    })

    $(".js-shop-selecter-dialog").on("mouseenter", function() {
      $(this).show()
    }).on("mouseleave", function() {
      $(this).fadeOut()
    })
  }

  // CharacterページのSort & Filter
  if ($(".js-navi-anime-character")[0]) {
    const languageList = {
      'lang_1': true,
      'lang_2': true,
      'lang_11': true,
      'lang_3': true,
      'lang_4': true,
      'lang_5': true,
      'lang_6': true,
      'lang_7': true,
      'lang_8': true,
      'lang_9': true,
      'lang_10': true
    };
    const languageLabel = {
      'lang_1': 'Japanese',
      'lang_2': 'English',
      'lang_11': 'Mandarin',
      'lang_3': 'Korean',
      'lang_4': 'Spanish',
      'lang_5': 'German',
      'lang_6': 'French',
      'lang_7': 'Portuguese (BR)',
      'lang_8': 'Italian',
      'lang_9': 'Hungarian',
      'lang_10': 'Hebrew'
    };
    const getLanguageList = function () {
      return Object.keys(languageList);
    }

    //filter button
    $(".js-navi-anime-character .js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");
      const $targetBlock = $(`.js-navi-anime-character .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-navi-anime-character .js-anime-character-sort-order-block.js-${id}`).hide();
        $(".js-navi-anime-character .js-btn-show-sort").removeClass("on");
      } else {
        $(".js-navi-anime-character .js-anime-character-sort-order-block").hide();
        $(".js-navi-anime-character .js-btn-show-sort").removeClass("on");
        $(`.js-navi-anime-character .js-anime-character-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        const showDialogInterval = setInterval(function() {
          closeAnimeCharacterFilterDialog();
          clearInterval(showDialogInterval);
        }, 500);
      }
    });

    //領域外クリックで閉じる
    const closeAnimeCharacterFilterDialog = function(id) {
      $(".js-navi-anime-character .js-anime-character-sort-order-block").on("click", function(e) {
        e.stopPropagation();
      });
      $(document).on("click", function() {
        $(".js-navi-anime-character .js-anime-character-sort-order-block").hide();
        $(".js-navi-anime-character .js-btn-show-sort").removeClass("on");
        $(document).off("click");
        $(".js-anime-character-table").click();
        return false;
      });
    }

    const changeAnimeCharacterFilter = function (btnId) {
      const $voice_actors = $(".js-anime-character-va");
      $(".js-anime-character-sort-order-block.js-language .js-btn-sort-order#All").removeClass("selected");
      $('#' + btnId).toggleClass("selected");
      checkAllButton("language");
      if ($('#' + btnId).hasClass("selected")) {
        languageList[btnId] = true;
      } else {
        languageList[btnId] = false;
      }
      $(".js-categories-anime-character .js-anime-character").show();
      $voice_actors.show();
      refreshSeiyuuBlocks();
    }

    //自動的に Allもチェックされる処理
    const checkAllButton = function(obj) {
      const $btnMylist = $(`.js-anime-character-sort-order-block.js-${obj} .js-btn-sort-order`);
      const selectedBtnAry = [];
      $btnMylist.each(function(){
        if ($(this).hasClass("selected") || $(this).hasClass("crossed")) {
          selectedBtnAry.push($(this).attr("id"));
        }
      });

      if (obj === "genres") {
        if (selectedBtnAry.length >= $btnMylist.length-1) {
          $btnMylist.addClass(function() {
            if (!$(this).hasClass("crossed")) {
              return "selected"
            }
          });
        }
        if (selectedBtnAry.length === 0) {
          $(`.js-btn-show-sort.${obj}`).removeClass("filtered");
        } else {
          $(`.js-btn-show-sort.${obj}`).addClass("filtered");
        }
      } else {
        if (selectedBtnAry.length >= $btnMylist.length-1) {
          $btnMylist.addClass(function() {
            if (!$(this).hasClass("crossed")) {
              return "selected"
            }
          });
          $(`.js-btn-show-sort.${obj}`).removeClass("filtered");
        } else {
          $(`.js-btn-show-sort.${obj}`).addClass("filtered");
        }
      }
    }

    // Fliterの選択エリア内の処理
    if ($(".js-anime-character-sort-order-block")[0]) {

      //close button
      $(".js-anime-character-sort-order-block .js-btn-close").on("click", function() {
        $(this).parents(".js-anime-character-sort-order-block").hide();
        $(".js-navi-anime-character .js-btn-show-sort").removeClass("on");
        $(".js-anime-character-table").click();
      });

      // language ボタン
      $(".js-anime-character-sort-order-block.js-language .js-btn-sort-order").on("click", function() {
        const btnId = $(this).attr("id");
        const $voice_actors = $(".js-anime-character-va");
        if (btnId === "All") {
          let checked = true;
          if ($(this).hasClass("selected")) {
            $(".js-anime-character-sort-order-block.js-language .js-btn-sort-order").removeClass("selected");
            $voice_actors.hide();
            $(".js-btn-show-sort.language").addClass("filtered");
            Object.keys(languageList).map(key => languageList[key] = false);
            checked = false;
          } else {
            $(".js-anime-character-sort-order-block.js-language .js-btn-sort-order").addClass("selected");
            $voice_actors.show();
            $(".js-btn-show-sort.language").removeClass("filtered");
            Object.keys(languageList).map(key => languageList[key] = true);
          }
          for (const item in languageList) {
            languageList[item] = checked;
          }
        } else {
          changeAnimeCharacterFilter(btnId);
        }
        refreshSeiyuuBlocks();
        localStorage.setItem('animeCharacterFilter', JSON.stringify(languageList));
      });
    }

    const animeCharacterSortEvent = function (btnId) {
      $("#js-anime-character-sort-title").html("Sorted by " + $("#" + btnId).html());
      $(".js-anime-character-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $("#" + btnId).addClass("selected");
      sortEntriesByMethod("chara", btnId);
    }

    // sort ボタン
    $(".js-anime-character-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
      localStorage.setItem('animeCharacterSort', $(this).attr("id"));
      animeCharacterSortEvent($(this).attr("id"));
      $(".js-anime-character-sort-order-block").hide();
      $(".js-navi-anime-character .js-btn-show-sort").removeClass("on");
      $(".js-anime-character-table").click();
    });


    const getCharaBlocks = function () {
      return [...document.querySelectorAll('.js-anime-character-table')]
    }

    const getAllSeiyuuBlocks = function () {
      return document.querySelectorAll('.js-anime-character-va-lang')
    }

    const getSeiyuuBlocksPerLang = function () {
      return getLanguageList().map(lang => ({
        language: lang,
        blocks: getSeiyuuBlocksForLang(lang)
      }))
    }

    const getSeiyuuBlocksForLang = function (language) {
      return [...getAllSeiyuuBlocks()]
        .filter(block => block.querySelector('.js-anime-character-language').innerHTML.includes(languageLabel[language]))
    }

    const sortEntriesByMethod = function (entryType, method) {
      const orderedBlocks = (method === 'anime-character-alphabetical')
        ? getCharaBlocks()
          .sort(blockCharaAlphabeticalSorter)
        : (method === 'anime-character-favorites')
          ? getCharaBlocks()
            .sort(blockCharaPopularitySorter)
          : getCharaBlocks()
            .sort(blockCharaDefaultSorter)

      var i = 0;
      orderedBlocks.forEach(el => {
        i++
        el.setAttribute('id', 'js-anime-character-' + i)
        $(".js-anime-character-container").append(el)
      })
      resetTableColors()
    }

    const blockCharaAlphabeticalSorter = function (a, b) {
      const aName = a.querySelector('.h3_character_name').innerHTML
      const bName = b.querySelector('.h3_character_name').innerHTML
      return (aName > bName) ?  1 :
        (aName < bName) ? -1 :
          0
    }

    const blockCharaDefaultSorter = function (a, b) {
      const aName = a.querySelector('.js-chara-roll-and-name').innerHTML
      const bName = b.querySelector('.js-chara-roll-and-name').innerHTML
      return (aName > bName) ?  1 :
        (aName < bName) ? -1 :
          0
    }

    const blockCharaPopularitySorter = function (a, b) {
      const aUserCount = a.querySelector('.js-anime-character-favorites').innerHTML
      const bUserCount = b.querySelector('.js-anime-character-favorites').innerHTML
      return bUserCount - aUserCount
    }

    const resetTableColors = function () {
      getCharaBlocks().forEach((el, i) => {
        const [classToRemove, classToAdd] = (i % 2 === 0)
          ? ['bgColor1', 'bgColor2']
          : ['bgColor2', 'bgColor1']
        el.querySelectorAll('tbody > tr > td')
          .forEach(el => el.classList.remove(classToRemove))
        el.querySelectorAll('tbody > tr > td')
          .forEach(el => el.classList.add(classToAdd))
      })
    }

    const refreshSeiyuuBlocks = function () {
      getSeiyuuBlocksPerLang()
        .filter(blockList => languageList[blockList.language] === false)
        .forEach(list => list.blocks.forEach(el => el.style.display = 'none'))

      getSeiyuuBlocksPerLang()
        .filter(blockList => languageList[blockList.language] === true)
        .forEach(list => list.blocks.forEach(el => el.style.display = 'table-row'))
    }

    if (localStorage['animeCharacterFilter']) {
      const json = JSON.parse(localStorage['animeCharacterFilter']);
      for (const key in languageList) {
        if (json[key] !== undefined) {
          languageList[key] = json[key];
          if (json[key] === false) {
            changeAnimeCharacterFilter(key);
          }
        }
      }
    }
    if (
        localStorage['animeCharacterSort'] == 'anime-character-alphabetical' ||
        localStorage['animeCharacterSort'] == 'anime-character-default' ||
        localStorage['animeCharacterSort'] =='anime-character-favorites'
    ) {
        animeCharacterSortEvent(localStorage['animeCharacterSort']);
    } else {
        animeCharacterSortEvent('anime-character-default');
    }
    $(".js-anime-character-container").show();
  }

  if ($(".js-navi-manga-character")[0]) {
    //close button
    $(".js-manga-character-sort-order-block .js-btn-close").on("click", function() {
      $(this).parents(".js-manga-character-sort-order-block").hide();
      $(".js-navi-manga-character .js-btn-show-sort").removeClass("on");
    });

    //filter button
    $(".js-navi-manga-character .js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");
      const $targetBlock = $(`.js-navi-manga-character .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-navi-manga-character .js-manga-character-sort-order-block.js-${id}`).hide();
        $(".js-navi-manga-character .js-btn-show-sort").removeClass("on");
      } else {
        $(".js-navi-manga-character .js-manga-character-sort-order-block").hide();
        $(".js-navi-manga-character .js-btn-show-sort").removeClass("on");
        $(`.js-navi-manga-character .js-manga-character-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        setTimeout(function () {
          $(document).on("click", function () {
            $(`.js-navi-manga-character .js-manga-character-sort-order-block.js-${id}`).hide();
            $(".js-navi-manga-character .js-btn-show-sort").removeClass("on");
            $(document).off("click")
            return false;
          });
        }, 0);

        const showDialogInterval = setInterval(function() {
          closeMangaCharacterFilterDialog();
          clearInterval(showDialogInterval);
        }, 500);
      }
    });

    //領域外クリックで閉じる
    const closeMangaCharacterFilterDialog = function(id) {
      $(".js-navi-manga-character .js-manga-character-sort-order-block").on("click", function(e) {
        e.stopPropagation();
      });
      $(document).on("click", function() {
        $(".js-navi-manga-character .js-manga-character-sort-order-block").hide();
        $(".js-navi-manga-character .js-btn-show-sort").removeClass("on");
        $(document).off("click");
        return false;
      });
    }

    const mangaCharacterSortEvent = function (btnId) {
      $("#js-manga-character-sort-title").html("Sorted by " + $("#" + btnId).html());
      $(".js-manga-character-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $("#" + btnId).addClass("selected");
      sortEntriesByMethod("chara", btnId);
    }

    // sort ボタン
    $(".js-manga-character-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
      localStorage.setItem('mangaCharacterSort', $(this).attr("id"));

      if ($('#manga-character-ad')[0]) {
        // 広告がある場合はリロード
        location.reload();
      } else {
        mangaCharacterSortEvent($(this).attr("id"));
        $(".js-manga-character-sort-order-block").hide();
        $(".js-navi-manga-character .js-btn-show-sort").removeClass("on");
        $(".js-manga-character-table").click();
      }
    });

    const getCharaBlocks = function () {
      return [...document.querySelectorAll('.js-manga-character-table')]
    }

    const sortEntriesByMethod = function (entryType, method) {
      // 広告がある場合は削除
      if ($('#manga-character-ad')[0]) {
        $('#manga-character-ad').insertAfter('.js-manga-character-container');
      }

      const orderedBlocks = (method === 'manga-character-alphabetical')
        ? getCharaBlocks()
          .sort(blockCharaAlphabeticalSorter)
        : (method === 'manga-character-favorites')
          ? getCharaBlocks()
            .sort(blockCharaPopularitySorter)
          : getCharaBlocks()
            .sort(blockCharaDefaultSorter)

      var i = 0;
      orderedBlocks.forEach(el => {
        i++
        el.setAttribute('id', 'js-manga-character-' + i)
        $(".js-manga-character-container").append(el)
      })
      resetTableColors()

      // 広告移動
      if ($('#manga-character-ad')[0]) {
        $('#manga-character-ad').insertAfter('#js-manga-character-9')
      }
    }

    const blockCharaAlphabeticalSorter = function (a, b) {
      const aName = a.querySelector('.h3_character_name').innerHTML
      const bName = b.querySelector('.h3_character_name').innerHTML
      return (aName > bName) ?  1 :
        (aName < bName) ? -1 :
          0
    }

    const blockCharaDefaultSorter = function (a, b) {
      const aName = a.querySelector('.js-chara-roll-and-name').innerHTML
      const bName = b.querySelector('.js-chara-roll-and-name').innerHTML
      return (aName > bName) ?  1 :
        (aName < bName) ? -1 :
          0
    }

    const blockCharaPopularitySorter = function (a, b) {
      const aUserCount = a.querySelector('.js-manga-character-favorites').innerHTML
      const bUserCount = b.querySelector('.js-manga-character-favorites').innerHTML
      return bUserCount - aUserCount
    }

    const resetTableColors = function () {
      getCharaBlocks().forEach((el, i) => {
        const [classToRemove, classToAdd] = (i % 2 === 0)
          ? ['bgColor1', 'bgColor2']
          : ['bgColor2', 'bgColor1']
        el.querySelectorAll('tbody > tr > td')
          .forEach(el => el.classList.remove(classToRemove))
        el.querySelectorAll('tbody > tr > td')
          .forEach(el => el.classList.add(classToAdd))
      })
    }

    if (
        localStorage['mangaCharacterSort'] == 'manga-character-alphabetical' ||
        localStorage['mangaCharacterSort'] == 'manga-character-default' ||
        localStorage['mangaCharacterSort'] =='manga-character-favorites'
    ) {
      mangaCharacterSortEvent(localStorage['mangaCharacterSort']);
    } else {
      mangaCharacterSortEvent('manga-character-default');
    }
    $(".js-manga-character-container").show();
  }

  // filter page
  if ($(".js-navi-company")[0]) {

    // show dialog buttons
    $(".js-navi-company .js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");
      const $targetBlock = $(`.js-navi-company .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-sort-order-block.js-${id}`).hide();
        $(".js-btn-show-sort").removeClass("on");
      } else {
        $(".js-sort-order-block").hide();
        $(".js-btn-show-sort").removeClass("on");
        $(`.js-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        const showDialogInterval = setInterval(function() {
            closeFilterDialog();
            clearInterval(showDialogInterval);
          }, 500);
      }
    });

    // click outside the area to close
    const closeFilterDialog = function(id) {
      $(".js-navi-company .js-sort-order-block").on("click", function(e) {
        e.stopPropagation();
      });
      $(document).on("click", function() {
        $(".js-navi-company .js-sort-order-block").hide();
        $(".js-navi-company .js-btn-show-sort").removeClass("on");
        $(document).off("click");
        return false;
      });
    }

    // close button
    $(".js-sort-order-block .js-btn-close").on("click", function() {
      $(this).parents(".js-sort-order-block").hide();
      $(".js-navi-company .js-btn-show-sort").removeClass("on");
      $(document).off("click");
    });

    // tab button
    $(".js-btn-anime-type").on("click", function() {
      $(".js-btn-anime-type").removeClass("on");
      $(this).addClass("on");
      localStorage.setItem('companyAnimeTab', $(this).data("key"));
      reflectsAnime();
    });

    // sort button
    $(".js-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
      $(".js-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
      $(this).addClass("selected");
      $(".js-text-sorted-by").text(companyAnimeSort[$(this).attr("id")]);
      localStorage.setItem('companyAnimeSort', $(this).attr("id"));
      sortAnime($(this).attr("id"));
    });

    // filter button
    $(".js-sort-order-block.js-categories .js-btn-sort-order").on("click", function() {
      $(this).toggleClass("selected");
      companyAnimeFilter[$(this).data("key")] = $(this).hasClass("selected");
      localStorage.setItem('companyAnimeFilter', JSON.stringify(companyAnimeFilter));
      reflectsAnime();
    });

    // mylist button
    $(".js-sort-order-block.js-mylist .js-btn-sort-order").on("click", function() {
      const btnId = $(this).attr("id");
      if (btnId === "All") {
        const sel = $(this).hasClass("selected");
        $(".js-sort-order-block.js-mylist .js-btn-sort-order").toggleClass("selected", !sel);
        Object.keys(companyAnimeMyList).map(key => companyAnimeMyList[key] = !sel);
      } else {
        $(".js-sort-order-block.js-mylist .js-btn-sort-order#All").removeClass("selected");
        $(this).toggleClass("selected");
        if ($(".js-sort-order-block.js-mylist .js-btn-sort-order.selected").length == 6) {
          $(".js-sort-order-block.js-mylist .js-btn-sort-order#All").addClass("selected");
        }
        companyAnimeMyList[btnId] = $(this).hasClass("selected");
      }
      localStorage.setItem('companyAnimeMyList', JSON.stringify(companyAnimeMyList));
      reflectsAnime();
    });

    // reflects the display　anime state (tab, filter, mylist)
    const reflectsAnime = function() {
      $(".js-middle_ad").hide();
      $(".js-categories-seasonal .js-seasonal-anime").show();

      // tab
      if ($(".js-btn-anime-type.on").data("key") != "all") {
        const btnTab = $(".js-btn-anime-type");
        btnTab.each(function(){
          if (!$(this).hasClass("on")) {
            const key = $(this).data("key");
            if (key != "all") {
              if (key == "0") { // Other(Unknown, Special, Music, CM, PV, TV Special)
                $(".js-anime-type-0").hide();
                $(".js-anime-type-4").hide();
                $(".js-anime-type-6").hide();
                $(".js-anime-type-7").hide();
                $(".js-anime-type-8").hide();
                $(".js-anime-type-9").hide();
              } else {
                $(`.js-anime-type-${key}`).hide();
              }
            }
          }
        });
      }

      // filter
      var filter_filtered = false;
      const btnFilter = $(".js-sort-order-block.js-categories .js-btn-sort-order");
      btnFilter.each(function(){
        if (!$(this).hasClass("selected")) {
          const key = $(this).data("key");
          $(`.js-anime-category-${key}`).hide();
          filter_filtered = true;
        }
      });
      $(".js-btn-show-sort.categories").toggleClass("filtered", filter_filtered);

      // mylist
      var mylist_filtered = false;
      const btnMylist = $(".js-sort-order-block.js-mylist .js-btn-sort-order");
      btnMylist.each(function(){
        if (!$(this).hasClass("selected")) {
          const id = $(this).attr("id");
          const seasonalAnime = $(`.js-categories-seasonal .js-seasonal-anime .js-anime-watch-status.${id}`).parents(".js-seasonal-anime");
          seasonalAnime.hide();
          mylist_filtered = true;
        }
      });
      $(".js-btn-show-sort.mylist").toggleClass("filtered", mylist_filtered);
      $(".js-categories-seasonal").show();
    }

    // sort anime
    const sortAnime = function(method) {
      $(".js-seasonal-anime").sort(blockSorter[method]).each(function(){
        $(".js-seasonal-anime-append").append(this);
      });
    }
    const blockSorter = {
      members: function(a, b) {
        const aMembers = a.querySelector('.js-members').innerHTML;
        const bMembers = b.querySelector('.js-members').innerHTML;
        return bMembers - aMembers;
      },
      score: function(a, b) {
        const aScore = a.querySelector('.js-score').innerHTML;
        const bScore = b.querySelector('.js-score').innerHTML;
        return bScore - aScore;
      },
      start_date: function(a, b) {
        const aDate = a.querySelector('.js-start_date').innerHTML;
        const bDate = b.querySelector('.js-start_date').innerHTML;
        return bDate - aDate;
      },
      title: function(a, b) {
        const aTitle = a.querySelector('.js-title').innerHTML;
        const bTitle = b.querySelector('.js-title').innerHTML;
        return (aTitle > bTitle) ? 1 : (aTitle < bTitle) ? -1 : 0
      }
    };

    // onload
    const companyAnimeSort = {
      members    : "Members",
      score      : "Score",
      start_date : "Newest",
      title      : "Title"
    };
    let sort = localStorage["companyAnimeSort"] || "members";
    $(".js-sort-order-block.js-sort .js-btn-sort-order#" + sort).addClass("selected");
    $(".js-text-sorted-by").text(companyAnimeSort[sort]);
    sortAnime(sort);
    const companyAnimeFilter = {
      "licensor": true,
      "studio": true,
      "producer": true
    };
    const companyAnimeMyList = {
      "notinmylist": true,
      "watching": true,
      "completed": true,
      "on-hold": true,
      "plantowatch": true,
      "dropped": true
    };
    const selectTab = localStorage["companyAnimeTab"] ? localStorage["companyAnimeTab"] : 'all';
    const btnTab = $(".js-btn-anime-type");
    btnTab.each(function(){
      $(this).toggleClass("on", String($(this).data("key")) === selectTab);
    });
    if (localStorage["companyAnimeFilter"]) {
      const json = JSON.parse(localStorage["companyAnimeFilter"]);
      for (const key in companyAnimeFilter) {
        if (json[key] !== undefined) {
          companyAnimeFilter[key] = json[key];
          if (json[key] === false) {
            $(".js-sort-order-block.js-categories .js-btn-sort-order[data-key=" + key + "]").removeClass("selected");
          }
        }
      }
    }
    if (localStorage["companyAnimeMyList"]) {
      const json = JSON.parse(localStorage["companyAnimeMyList"]);
      let all = false;
      for (const key in companyAnimeMyList) {
        if (json[key] !== undefined) {
          companyAnimeMyList[key] = json[key];
          if (json[key] === false) {
            $(".js-sort-order-block.js-mylist .js-btn-sort-order#" + key).removeClass("selected");
            all = true;
          }
        }
      }
      if (all) {
        $(".js-sort-order-block.js-mylist .js-btn-sort-order#All").removeClass("selected");
      }
    }
    reflectsAnime();
  }
});
